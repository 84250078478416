'use client'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import { TRACKER_EVENTS } from '@paladise/tracker/constants/trackerEventConstant'
import { Link } from 'lib/navigation'
import { useTranslations } from 'next-intl'
import React from 'react'
import { useDevice } from 'store/provider/device-provider'
import { tv } from 'tailwind-variants'

const links = [
  {
    href: '/enterprise/dashboard',
    text: 'my_pro_agents',
    icon: 'i-ps-group_s',
    trackerEventName: TRACKER_EVENTS.list_myaibot_click,
  },
  {
    href: '/enterprise/account',
    text: 'common.account',
    icon: 'i-ps-user_rou_s',
    trackerEventName: TRACKER_EVENTS.list_account_click,
  },
  {
    href: '/enterprise/plans',
    text: 'your_plans',
    icon: 'i-ps-document_s',
    trackerEventName: TRACKER_EVENTS.list_plans_click,
  },
  {
    href: '/enterprise/payment',
    text: 'payment_method',
    icon: 'i-ps-credit_card_s',
    trackerEventName: TRACKER_EVENTS.list_paymentmethod_click,
  },
  {
    href: '/#',
    text: 'about',
    icon: 'i-ps-question_s',
    trackerEventName: TRACKER_EVENTS.list_about_click,
  },
] as const

const button = tv({
  base: 'flex p-[4px]',
  variants: {
    theme: {
      light: 'text-label-l1',
      dark: 'text-label-still-l1',
    },
  },
})

const hoverCard = tv({
  slots: {
    content: [
      'isolate overflow-hidden rounded-[20px] p-0 [--icon-size:24px]',
      'z-popover bg-background-1st shadow-floating-dropdown rounded-[12px] outline-none',
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
    ],
    listLink: 'flex w-[240px] items-center space-x-[16px] px-[24px] py-[16px]',
  },
  variants: {
    theme: {
      light: {
        content: 'bg-floating text-label-l1',
        listLink: 'hover:bg-background-elevated-b1',
      },
      dark: {
        content: 'bg-floating-still text-label-still-l1',
        listLink: 'hover:bg-background-elevated-still-1st',
      },
    },
  },
})

const dialog = tv({
  slots: {
    overlay:
      'bg-overlay-light data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 min768:hidden fixed bottom-0 left-0 right-0 top-0 z-0 backdrop-blur-[50px] duration-100',
    content:
      'text-label-still-l1 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 min768:hidden group fixed left-0 right-0 top-[48px] flex h-fit justify-center pt-[24px] outline-none duration-100 [--icon-size:24px]',
    listItem: [
      'min768:w-[96px] min768:p-0 w-[240px] px-[24px] py-[16px]',
      'group-data-[state=open]:animate-in group-data-[state=closed]:animate-out group-data-[state=closed]:slide-out-to-bottom-[--percent] group-data-[state=open]:slide-in-from-bottom-[--percent] duration-100',
    ],
  },
})

const HamburgerMenu = ({ theme }: { theme: 'light' | 'dark' }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const t = useTranslations()
  const device = useDevice()

  if (device === 'desktop') {
    const { listLink, content } = hoverCard({ theme })
    return (
      <HoverCardPrimitive.Root openDelay={0}>
        <HoverCardPrimitive.Trigger asChild>
          <button
            data-tracker-click={TRACKER_EVENTS.icon_menu_click}
            className={button({ theme })}
          >
            <span className="i-ps-menu [--icon-size:24px]"></span>
          </button>
        </HoverCardPrimitive.Trigger>
        <HoverCardPrimitive.Portal>
          <HoverCardPrimitive.Content className={content()} align="end" asChild>
            <nav>
              <ul className="flex flex-col">
                {links.map(({ href, text, icon, trackerEventName }, index) => (
                  <li key={index}>
                    <Link
                      href={href}
                      title={t(text)}
                      className={listLink()}
                      onClick={() => setIsOpen(false)}
                      data-tracker-click={trackerEventName}
                    >
                      <span className={icon}></span>
                      <span>{t(text)}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </HoverCardPrimitive.Content>
        </HoverCardPrimitive.Portal>
      </HoverCardPrimitive.Root>
    )
  }

  const { listItem, content, overlay } = dialog({ theme })

  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={setIsOpen} modal>
      <DialogPrimitive.Trigger asChild>
        <button
          data-tracker-click={TRACKER_EVENTS.icon_menu_click}
          className={button({ theme })}
        >
          <span className="i-ps-menu [--icon-size:24px]"></span>
        </button>
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className={overlay()} />
        <DialogPrimitive.Content className={content()} asChild>
          <nav>
            <ul className="min768:flex-row flex flex-col text-2xl">
              {links.map(({ href, text, icon, trackerEventName }, index) => (
                <li
                  key={index}
                  className={listItem()}
                  style={{ '--percent': `${index * -100}%` }}
                >
                  <Link
                    href={href}
                    title={text}
                    className="min768:flex-col min768:justify-center min768:space-x-0 min768:space-y-[6px] flex items-center space-x-[16px]"
                    onClick={() => setIsOpen(false)}
                    data-tracker-click={trackerEventName}
                  >
                    <span className={icon}></span>
                    <span>{t(text)}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

export default HamburgerMenu
