import * as localeCodes from 'locale-codes'

export const LANGUAGE = [
  {
    key: 'ar',
    value: 'ar',
    name: 'اللغة العربية',
  },
  {
    key: 'de',
    value: 'de',
    name: 'Deutsch',
  },
  {
    key: 'en',
    value: 'en',
    name: 'English',
  },
  {
    key: 'es',
    value: 'es',
    name: 'Español',
  },
  {
    key: 'fr',
    value: 'fr',
    name: 'Français',
  },
  {
    key: 'hi',
    value: 'hi',
    name: 'हिंदी',
  },
  {
    key: 'id',
    value: 'id',
    name: 'Bahasa Indonesia',
  },
  {
    key: 'it',
    value: 'it',
    name: 'Italiano',
  },
  {
    key: 'ja',
    value: 'ja',
    name: '日本語',
  },
  {
    key: 'ko',
    value: 'ko',
    name: '한국어',
  },
  {
    key: 'ms',
    value: 'ms',
    name: 'Bahasa Melayu',
  },
  {
    key: 'pl',
    value: 'pl',
    name: 'Polski',
  },
  {
    key: 'pt',
    value: 'pt',
    name: 'Português',
  },
  {
    key: 'ru',
    value: 'ru',
    name: 'Русский',
  },
  {
    key: 'th',
    value: 'th',
    name: 'ภาษาไทย',
  },
  {
    key: 'tr',
    value: 'tr',
    name: 'Türkçe',
  },
  {
    key: 'vi',
    value: 'vi',
    name: 'Tiếng Việt',
  },
  {
    key: 'zh-CN',
    value: 'zh-CN',
    name: '简体中文',
  },
  {
    key: 'zh-TW',
    value: 'zh-TW',
    name: '繁體中文',
  },
]

export const locales = LANGUAGE.map(l => l.value)

export const DEFAULT_LANGUAGE = 'en'

type Resources = Record<string, { translation?: string; redirect?: string }>

export const LANGUAGE_RESOURCES: Resources = {
  en: {
    translation: 'en',
  },
  ar: {
    translation: 'ar',
  },
  de: {
    translation: 'de',
  },
  es: {
    translation: 'es',
  },
  fr: {
    translation: 'fr',
  },
  hi: {
    translation: 'hi',
  },
  id: {
    translation: 'id',
  },
  it: {
    translation: 'it',
  },
  ja: {
    translation: 'ja',
  },
  jp: {
    redirect: 'ja',
  },
  ko: {
    translation: 'ko',
  },
  ms: {
    translation: 'ms',
  },
  pl: {
    translation: 'pl',
  },
  pt: {
    translation: 'pt',
  },
  ru: {
    translation: 'ru',
  },
  th: {
    translation: 'th',
  },
  tr: {
    translation: 'tr',
  },
  vi: {
    translation: 'vi',
  },
  zh: {
    redirect: 'zh-TW',
  },
  'zh-CN': {
    translation: 'zh-CN',
  },
  'zh-Hans': {
    redirect: 'zh-CN',
  },
  'zh-TW': {
    translation: 'zh-TW',
  },
  'zh-Hant': {
    redirect: 'zh-TW',
  },
}

const getResource = (key: string, resource: Resources[string]) => {
  if (resource.translation && resource.translation !== key) {
    return {
      [key]: {
        redirect: resource.translation,
      },
    }
  }

  return {
    [key]: resource,
  }
}

export const SUPPORTED_LANGUAGES = localeCodes.all
  .map(locale => {
    const iso6391 = locale['iso639-1']
    const tag = locale.tag
    if (!tag && !iso6391) {
      return null
    }
    const tagResource = LANGUAGE_RESOURCES[tag]
    const isoResource = iso6391 ? LANGUAGE_RESOURCES[iso6391] : null

    const resources = tagResource ?? isoResource ?? null
    if (!resources) {
      return null
    }

    let obj = getResource(tag, resources)
    if (iso6391) {
      obj = {
        ...obj,
        ...getResource(iso6391, resources),
      }
    }
    return obj
  })
  .filter((locale): locale is Resources => !!locale)
  .reduce((acc, locale) => {
    const key = Object.keys(locale)[0]
    acc[key] = locale[key]
    return acc
  }, LANGUAGE_RESOURCES)
