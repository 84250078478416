'use client'

import { TRACKER_EVENTS } from '@paladise/tracker/constants/trackerEventConstant'
import { useLanguageSelect } from './use-language-select'

interface ILanguageSelector {
  className?: string | undefined
}
const LanguageSelector = (props: ILanguageSelector) => {
  const { locale, localeName, handleLocalesChange, localeList } =
    useLanguageSelect()

  return (
    <div className="relative">
      <div className="flex items-center justify-between space-x-[4px] text-current">
        <span>{localeName}</span>
        <div className="i-ps-chevron_down [--icon-size:16px]" />
      </div>
      <select
        data-tracker-click={TRACKER_EVENTS.textbar_language_click}
        value={locale}
        className={`absolute left-0 top-0 h-full w-full border-none opacity-0 ${props.className}`}
        onChange={handleLocalesChange}
      >
        <option value={locale}>{localeName}</option>
        {localeList.map(locale => (
          <option key={locale.name} value={locale.name} className="">
            {locale.title}
          </option>
        ))}
      </select>
    </div>
  )
}

export default LanguageSelector
